export const useFormatSidebarTitle = (text: string, prefix: string) => {
  function clearAndUpper(text) {
    return text.replace(/-/, "").toUpperCase();
  }

  const withoutPrefix = text?.replace(`${prefix}-`, "");

  const pascalCased = withoutPrefix?.replace(/(^\w|-\w)/g, clearAndUpper);

  const result = pascalCased?.replace(/([A-Z])/g, " $1");

  const finalResult = result?.charAt(0).toUpperCase() + result?.slice(1);

  return finalResult || "";
};
